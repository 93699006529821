import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
import stripe from 'stripe'
import axios from 'axios'
const stripeClient = stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
const baseUrl =
  process.env.VUE_APP_ENVIRONMENT === 'development' ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL_PRO

const browserLocale = navigator.language.split('-')[0]

console.log('process.env.VUE_APP_ENVIRONMENT', process.env.VUE_APP_ENVIRONMENT)
console.log('process.env.VUE_APP_API_URL_DEV', process.env.VUE_APP_API_URL_DEV)
console.log('condition', baseUrl)

const formatDateTime = (dateTime) => {
  return browserLocale === 'ja'
    ? format(dateTime, 'yyyy/MM/dd (eee) HH:mm', { locale: ja })
    : format(dateTime, 'yyyy/MM/dd (eee) HH:mm')
}

async function uploadFile(file) {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)

      reader.onload = async () => {
        const fileData = new Uint8Array(reader.result)
        try {
          const upload = await stripeClient.files.create({
            file: {
              data: fileData,
              name: file.name,
              type: 'application/octet-stream'
            },
            purpose: 'identity_document'
          })
          console.log('File uploaded successfully:', upload)
          resolve(upload)
        } catch (error) {
          console.log('Error uploading file', error)
          reject(error)
        }
      }

      reader.onerror = (error) => {
        console.error('File reading error', error)
        reject(error)
      }
    })
  } catch (error) {
    console.log('Error uploading file', error)
    throw error
  }
}

async function postCreateShopOwner(payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(`${baseUrl}/createShopOwner`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
}

async function getShopOwnerById(id) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios(`${baseUrl}/getShopOwner?shopOwnerId=${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

async function shopOwnerUpdate(payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.put(`${baseUrl}/updateShopOwnerByAdmin`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export { browserLocale, formatDateTime, uploadFile, postCreateShopOwner, getShopOwnerById, shopOwnerUpdate }
