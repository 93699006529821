import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/MyHome.vue')
  },
  {
    path: '/sign-in',
    name: 'signIn',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue')
  },
  {
    path: '/sign-out',
    name: 'signOut',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignOut.vue')
  },
  {
    path: '/shop-owners/create',
    name: 'createShopOwner',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateShopOwner.vue')
  },
  {
    path: '/shop-owners/:shopOwnerId/update',
    name: 'updateShopOwner',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateShopOwner.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // const { meta } = to
  // if (meta.signInRequired) {
  //   if (store.state.client.id === null) {
  //     next({ name: 'signIn', replace: true })
  //   } else {
  //     next()
  //   }
  // } else {
  next()
  // }
})

export default router
