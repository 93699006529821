/**
 * @type {firebase.default.firestore.Firestore}
 */
let db = null;

/**
 * @type {firebase.default}
 */
let firebase = null;

const getDb = () => db;

/**
 * @returns {firebase.default}
 */
const getFirebase = () => firebase;

const setDb = (_db, _firebase) => {
  db = _db;
  firebase = _firebase;
};

module.exports = {
  getDb,
  setDb,
  getFirebase,
};
