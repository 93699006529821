import '@/plugins/veevalidate'
import '@/plugins/vuemeta'
import { firebaseFirestore, firebase } from '@/services/firebase'
import { initShared } from '@shared/index'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

initShared(firebaseFirestore, firebase)

Vue.config.productionTip = false

Vue.use({ vuetify })
Vue.use(Toast)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
